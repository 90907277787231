var modulScroll = (($) => {
	'use strict';

	function listen() {
		if (!$('.go_to').length) return;

		$(".go_to").click(function() {
			var scroll_el = $(this).attr('href');
			if ($(scroll_el).length !== 0) {
				$('body,html').animate({ scrollTop: $(scroll_el).offset().top -88 }, 500);
			}
			return false;
		});
	}
	return {
		init: listen
	};
})(jQuery);

export default modulScroll;