import customSelect from './modules/tabHover';
import modulScroll from './modules/scroll';
import fixed from './modules/fixed';
import bodyBg from './modules/bodyBg';

( ($) => {
	'use strict';

	$(() => {
		bodyBg.init();
		customSelect.init();
		modulScroll.init();
		fixed.init();
	});
})(jQuery);