var tabHover = (($) => {
	'use strict';

	function init() {
		//$(document).off('click.bs.tab.data-api', '[data-hover="tab"]');
		$(document).on('mouseenter.bs.tab.data-api', '[data-toggle="tab"], [data-hover="tab"]', function () {
			$(this).tab('show');
		});
	}

	return {
		init: init
	};
})(jQuery);

export default tabHover;