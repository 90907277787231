var scrollTitle = (function($) {
	'use strict';

	function InitScroll() {
		var header = $(".title-panel");
		$(window).scroll(function() {
			var scroll = $(window).scrollTop();

			if (scroll >= 1) {
				header.addClass("fixed");
			} else {
				header.removeClass("fixed");
			}
		});
	}

	return {
		init: InitScroll
	};

}(jQuery));

export default scrollTitle;