var bodyBg = (($) => {
	'use strict';

	function init() {

			if(screen.width < 767) return;
			setBg();
			$(window).on('resize',setBg);
	}
	function setBg() {
		if ($(".nav-pills li").data('color')) {
			$(".nav-pills li").on('mouseenter',function() {
				$("body").css('backgroundColor', $(this).data('color'));
			});
			$(".nav-pills li a").on('mouseenter',function() {
				$(".nav-pills .desktop-link").removeClass('no-opacity');
			});
		}
	}

	return {
		init: init
	};

})(jQuery);

export default bodyBg;